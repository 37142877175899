<template>
  <history-list-card
    :title="title"
    :items="listItems"
    @click:back="handleBack"
    @infinite="loadMore"
  />
</template>

<script>
import { useNotifications } from '@/modules/notifications/compositions/notifications';
import { onMounted, toRefs } from '@vue/composition-api';

export default {
  name: 'HistoryNotificatoinList',
  props: {
    objectId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { listItems, load, loadMore } = useNotifications(props.objectId);
    const handleBack = () => {
      emit('stack:back');
    };

    onMounted(() => load());

    return {
      title: 'Notifications history',
      listItems,
      handleBack,
      loadMore
    };
  }
};
</script>

<style></style>
